/* TodoList.css */

.board {
  margin-top: 80px;
  display: flex;
}

.column {
  flex: 2;
  background-color: #fff;
  border-radius: 4px;
  margin: 0 10px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.column h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.task-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.task {
  background-color: #f7f7f7;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  color: #333;
  transition: background-color 0.3s ease;
  position: relative; /* Add this line to make it relative for absolute positioning */
}

.task:hover {
  background-color: #eee;
}

.add-task {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.add-task input[type="text"] {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 14px;
  color: #333;
}

.add-task button {
  padding: 8px 12px;
  background-color: #333;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-task button:hover {
  background-color: #555;
}

.column button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #555;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.column button:hover {
  background-color: #777;
}

.task .task-icon {
  position: relative;
}

.fa-button {
  margin-left: 5px;
}