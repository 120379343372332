@import url(https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap);
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Bree Serif', serif;
  /* background-image: linear-gradient(to top, #f43b47 0%, #453a94 100%); */

}

.coin-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px; /* Add margin-top to account for the navbar height */
}

.coin-search{
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.coin-text{
  margin-bottom: 32px;
  text-align: center;
}

.coin-input{
  padding-left: 16px;
  width: 300px;
  height: 50px;
  border-radius: 4px;
  border: none;
  background-image: linear-gradient(to right, #3ab5b0 0%, #3d99be 31%, #56317a 100%);
  /* background-image: linear-gradient(to top, #fcc5e4 0%, #fda34b 15%, #ff7882 35%, #c8699e 52%, #7046aa 71%, #0c1db8 87%, #020f75 100%); */
  color: #e2e2e2;
}

.coin-input::-webkit-input-placeholder{
  color: #e2e2e2;
}

.coin-input::placeholder{
  color: #e2e2e2;
}
table {
  width: 80%;
  border-collapse: collapse;
  font-size: 1rem;
}

th {
  text-align: left;
  background-color: #f2f2f2;
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

th:first-child,
td:first-child {
  padding-left: 20px;
}

th:last-child,
td:last-child {
  padding-right: 20px;
}

td {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

tr:hover {
  background-color: #f5f5f5;
}

.coin-container .coin-row .coin img {
  height: 30px !important; /* adjust the height as needed */
  width: auto; /* preserve the aspect ratio of the image */
  margin-right: 10px !important;
}

.coin .coin-details {
  display: flex;
  align-items: center;
}

.coin .coin-details img {
  height: 30px;
  margin-right: 10px;
}

.coin .coin-details h1 {
  font-size: 1rem;
  margin: 0;
}

.coin .coin-details p {
  font-size: 0.8rem;
  margin: 0;
}

.coin h1 {
  font-size: 1rem;
  margin: 0;
}

.coin-symbol {
  font-size: 0.8rem;
  margin: 0;
}

.coin-percent {
  font-size: 0.8rem;
  margin: 0;
}

.red {
  color: #f44336;
}

.green {
  color: #4caf50;
}

/*  Tooltip Styling  */
.tooltip {
  position: relative;
}

.tooltip::after {
  content: 'Tooltip Text';
  position: absolute;
  top: 50%;
  right: calc(100% + 10px);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tooltip:hover::after {
  opacity: 1;
}

.navbar {
  background-color: #1f1f1f;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.navbar-title {
  font-size: 2rem;
  margin: 0;
  margin-left: 20px;
}

.navbar-input {
  padding: 8px;
  border-radius: 4px;
  border: none;
  background-color: #282828;
  color: white;
  width: 200px;
}

.navbar-input::-webkit-input-placeholder {
  color: #a0a0a0;
}

.navbar-input::placeholder {
  color: #a0a0a0;
}
/* TodoList.css */

.board {
  margin-top: 80px;
  display: flex;
}

.column {
  flex: 2 1;
  background-color: #fff;
  border-radius: 4px;
  margin: 0 10px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.column h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.task-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.task {
  background-color: #f7f7f7;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  color: #333;
  transition: background-color 0.3s ease;
  position: relative; /* Add this line to make it relative for absolute positioning */
}

.task:hover {
  background-color: #eee;
}

.add-task {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.add-task input[type="text"] {
  flex: 1 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 14px;
  color: #333;
}

.add-task button {
  padding: 8px 12px;
  background-color: #333;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-task button:hover {
  background-color: #555;
}

.column button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #555;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.column button:hover {
  background-color: #777;
}

.task .task-icon {
  position: relative;
}

.fa-button {
  margin-left: 5px;
}
.navbar {
  display: flex;
  align-items: center;
  background-color: #000000;
  padding: 10px;
}

.title {
  flex: 1 1; /* Add flex property to take available space */
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-between; /* Add this to distribute the space evenly */
  min-width: 55vw; /* Adjust the width as needed */
  max-width: 100vw;
  left: 20vw;
  position: absolute;
}

.nav-item {
  margin-right: 10px;
}

.nav-link {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}

.nav-link:hover {
  color: #ccc;
}

.card-body {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
  background-size: cover;
}

.card {
  display: grid;
  grid-template-columns: 350px;
  /* Adjust the minmax values as needed */
  grid-template-rows: 250px 290px 20px;
  grid-template-areas: "image" "text" "stats";
  border-radius: 18px;
  background: #1d1d1d;
  color: white;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.9);
  font-family: roboto;
  text-align: justify;
  cursor: pointer;
  margin: 30px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: perspective(1000px);
          transform: perspective(1000px);
  margin-top: 100px;
  text-decoration: none;
}

.card:hover {
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
}

.card:hover::after {
  content: "";
  background: linear-gradient(45deg,
      #ff0000 0%,
      #ff9a00 10%,
      #d0de21 20%,
      #4fdc4a 30%,
      #3fdad8 40%,
      #2fc9e2 50%,
      #1c7fee 60%,
      #5f15f2 70%,
      #ba0cf8 80%,
      #fb07d9 90%,
      #ff0000 100%) repeat 0% 0% / 300% 100%;
  position: absolute;
  inset: -3px;
  border-radius: 16px;
  -webkit-filter: blur(8px);
          filter: blur(8px);
  -webkit-transform: translateZ(-1px);
          transform: translateZ(-1px);
  /*or z-index */
  -webkit-animation: rgb 2s linear infinite;
          animation: rgb 2s linear infinite;
}

.rgb::after {
  content: "";
  background: linear-gradient(45deg,
      #ff0000 0%,
      #ff9a00 10%,
      #d0de21 20%,
      #4fdc4a 30%,
      #3fdad8 40%,
      #2fc9e2 50%,
      #1c7fee 60%,
      #5f15f2 70%,
      #ba0cf8 80%,
      #fb07d9 90%,
      #ff0000 100%) repeat 0% 0% / 300% 100%;
  position: absolute;
  inset: -3px;
  border-radius: 16px;
  -webkit-filter: blur(8px);
          filter: blur(8px);
  -webkit-transform: translateZ(-1px);
          transform: translateZ(-1px);
  /*or z-index */
  -webkit-animation: rgb 6s linear infinite;
          animation: rgb 6s linear infinite;
}

@-webkit-keyframes rgb {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes rgb {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.js-tilt-glare {
  border-radius: 18px;
}

.card-image {
  grid-area: image;
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%), url(/static/media/CryptoIQ.f9267aff.jpg);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-size: cover;
}

.card-text {
  grid-area: text;
  margin: 25px;
  -webkit-transform: translateZ(30px);
          transform: translateZ(30px);
}

.card-text .languages {
  color: rgb(57, 255, 7);
  font-size: 13px;
  position: absolute;
  bottom: 0;
}

.card-text p {
  color: grey;
  font-size: 14px;
  font-weight: 300;
}

.card-text h2 {
  margin-top: 0px;
  font-size: 28px;
}

.card-stats {
  grid-area: stats;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: rgb(255, 7, 110);
}

.card-stats .stat {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.card-stats .border {
  border-left: 1px solid rgb(172, 26, 87);
  border-right: 1px solid rgb(172, 26, 87);
}

.card-stats .value {
  font-size: 22px;
  font-weight: 500;
}

.card-stats .value sup {
  font-size: 12px;
}

.card-stats .type {
  font-size: 11px;
  font-weight: 300;
  text-transform: uppercase;
}


/*card2*/
.card-image.card2 {
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%), url(/static/media/country-checklist.5821a9fb.jpg);
  background-size: cover;
}

.card-text.card2 .languages {
  color: rgb(57, 255, 7);
}

.card-stats.card2 .border {
  border-left: 1px solid rgb(185, 67, 20);
  border-right: 1px solid rgb(185, 67, 20);
}

.card-stats.card2 {
  background: rgb(255, 77, 7);
}

/*card3*/
.card-image.card3 {
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%), url(/static/media/todo-list.8229eaaf.jpg);
  background-size: cover;
}

.card-text.card3 .languages {
  color: rgb(57, 255, 7);
  bottom: 0;
}

.card-stats.card3 .border {
  border-left: 1px solid rgb(14, 122, 50);
  border-right: 1px solid rgb(14, 122, 50);
}

.card-stats.card3 {
  background: rgb(0, 189, 63);
}

.card-image.card4 {
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%), url(/static/media/finances-card.19828b8a.jpg);
  background-size: cover;
}

.card-image.card5 {
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%), url(/static/media/journal.7ced7cee.png);
  background-size: cover;
}
.login-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 50vw;
  width: 50vw;
  height: 20vw;
  margin: 0 auto;
  padding: 40px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

button:hover {
  background-color: #0056b3;
}
/* Reset default margin and padding */
.journal-body, h1, h2, p, ul, li, blockquote {
  margin: 0;
  padding: 0;
}

.journal-body {
  margin-top: 75px;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

header {  
  background-color: #333;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav ul {
  list-style: none;
  display: flex;
}

nav li {
  margin-right: 20px;
  cursor: pointer;
}

main {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.journal-entry textarea {
  width: 100%;
  height: 150px;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.rating {
  display: flex;
  align-items: center;
}

.rating p {
  margin-right: 10px;
}

.rating button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.inspirational-quote blockquote {
  font-style: italic;
  margin-top: 10px;
}

.streak-and-count {
  text-align: center;
  margin-top: 20px;
}

/* 
.calendar {
  Add your calendar styling here
} */

footer {
  text-align: center;
  padding: 10px;
  background-color: #333;
  color: white;
}

