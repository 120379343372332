.card-body {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
  background-size: cover;
}

.card {
  display: grid;
  grid-template-columns: 350px;
  /* Adjust the minmax values as needed */
  grid-template-rows: 250px 290px 20px;
  grid-template-areas: "image" "text" "stats";
  border-radius: 18px;
  background: #1d1d1d;
  color: white;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.9);
  font-family: roboto;
  text-align: justify;
  cursor: pointer;
  margin: 30px;
  transform-style: preserve-3d;
  transform: perspective(1000px);
  margin-top: 100px;
  text-decoration: none;
}

.card:hover {
  transform: scale(1.025);
}

.card:hover::after {
  content: "";
  background: linear-gradient(45deg,
      #ff0000 0%,
      #ff9a00 10%,
      #d0de21 20%,
      #4fdc4a 30%,
      #3fdad8 40%,
      #2fc9e2 50%,
      #1c7fee 60%,
      #5f15f2 70%,
      #ba0cf8 80%,
      #fb07d9 90%,
      #ff0000 100%) repeat 0% 0% / 300% 100%;
  position: absolute;
  inset: -3px;
  border-radius: 16px;
  filter: blur(8px);
  transform: translateZ(-1px);
  /*or z-index */
  animation: rgb 2s linear infinite;
}

.rgb::after {
  content: "";
  background: linear-gradient(45deg,
      #ff0000 0%,
      #ff9a00 10%,
      #d0de21 20%,
      #4fdc4a 30%,
      #3fdad8 40%,
      #2fc9e2 50%,
      #1c7fee 60%,
      #5f15f2 70%,
      #ba0cf8 80%,
      #fb07d9 90%,
      #ff0000 100%) repeat 0% 0% / 300% 100%;
  position: absolute;
  inset: -3px;
  border-radius: 16px;
  filter: blur(8px);
  transform: translateZ(-1px);
  /*or z-index */
  animation: rgb 6s linear infinite;
}

@keyframes rgb {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.js-tilt-glare {
  border-radius: 18px;
}

.card-image {
  grid-area: image;
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%), url("../imgs/CryptoIQ.jpg");
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-size: cover;
}

.card-text {
  grid-area: text;
  margin: 25px;
  transform: translateZ(30px);
}

.card-text .languages {
  color: rgb(57, 255, 7);
  font-size: 13px;
  position: absolute;
  bottom: 0;
}

.card-text p {
  color: grey;
  font-size: 14px;
  font-weight: 300;
}

.card-text h2 {
  margin-top: 0px;
  font-size: 28px;
}

.card-stats {
  grid-area: stats;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: rgb(255, 7, 110);
}

.card-stats .stat {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.card-stats .border {
  border-left: 1px solid rgb(172, 26, 87);
  border-right: 1px solid rgb(172, 26, 87);
}

.card-stats .value {
  font-size: 22px;
  font-weight: 500;
}

.card-stats .value sup {
  font-size: 12px;
}

.card-stats .type {
  font-size: 11px;
  font-weight: 300;
  text-transform: uppercase;
}


/*card2*/
.card-image.card2 {
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%), url("../imgs/country-checklist.jpg");
  background-size: cover;
}

.card-text.card2 .languages {
  color: rgb(57, 255, 7);
}

.card-stats.card2 .border {
  border-left: 1px solid rgb(185, 67, 20);
  border-right: 1px solid rgb(185, 67, 20);
}

.card-stats.card2 {
  background: rgb(255, 77, 7);
}

/*card3*/
.card-image.card3 {
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%), url("../imgs/todo-list.jpg");
  background-size: cover;
}

.card-text.card3 .languages {
  color: rgb(57, 255, 7);
  bottom: 0;
}

.card-stats.card3 .border {
  border-left: 1px solid rgb(14, 122, 50);
  border-right: 1px solid rgb(14, 122, 50);
}

.card-stats.card3 {
  background: rgb(0, 189, 63);
}

.card-image.card4 {
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%), url("../imgs/finances-card.jpg");
  background-size: cover;
}

.card-image.card5 {
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%), url("../imgs/journal.png");
  background-size: cover;
}