.navbar {
  background-color: #1f1f1f;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.navbar-title {
  font-size: 2rem;
  margin: 0;
  margin-left: 20px;
}

.navbar-input {
  padding: 8px;
  border-radius: 4px;
  border: none;
  background-color: #282828;
  color: white;
  width: 200px;
}

.navbar-input::placeholder {
  color: #a0a0a0;
}