.navbar {
  display: flex;
  align-items: center;
  background-color: #000000;
  padding: 10px;
}

.title {
  flex: 1; /* Add flex property to take available space */
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-between; /* Add this to distribute the space evenly */
  min-width: 55vw; /* Adjust the width as needed */
  max-width: 100vw;
  left: 20vw;
  position: absolute;
}

.nav-item {
  margin-right: 10px;
}

.nav-link {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}

.nav-link:hover {
  color: #ccc;
}
