@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Bree Serif', serif;
  /* background-image: linear-gradient(to top, #f43b47 0%, #453a94 100%); */

}

.coin-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px; /* Add margin-top to account for the navbar height */
}

.coin-search{
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.coin-text{
  margin-bottom: 32px;
  text-align: center;
}

.coin-input{
  padding-left: 16px;
  width: 300px;
  height: 50px;
  border-radius: 4px;
  border: none;
  background-image: linear-gradient(to right, #3ab5b0 0%, #3d99be 31%, #56317a 100%);
  /* background-image: linear-gradient(to top, #fcc5e4 0%, #fda34b 15%, #ff7882 35%, #c8699e 52%, #7046aa 71%, #0c1db8 87%, #020f75 100%); */
  color: #e2e2e2;
}

.coin-input::placeholder{
  color: #e2e2e2;
}