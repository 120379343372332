/* Reset default margin and padding */
.journal-body, h1, h2, p, ul, li, blockquote {
  margin: 0;
  padding: 0;
}

.journal-body {
  margin-top: 75px;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

header {  
  background-color: #333;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav ul {
  list-style: none;
  display: flex;
}

nav li {
  margin-right: 20px;
  cursor: pointer;
}

main {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.journal-entry textarea {
  width: 100%;
  height: 150px;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.rating {
  display: flex;
  align-items: center;
}

.rating p {
  margin-right: 10px;
}

.rating button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.inspirational-quote blockquote {
  font-style: italic;
  margin-top: 10px;
}

.streak-and-count {
  text-align: center;
  margin-top: 20px;
}

/* 
.calendar {
  Add your calendar styling here
} */

footer {
  text-align: center;
  padding: 10px;
  background-color: #333;
  color: white;
}
